* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Roboto";
}

#root {
  min-height: 100vh;
}

.swal2-container {
  z-index: 2000 !important;
}

.swal2-icon.swal2-error {
  border-color: #ed2518 !important;
  color: #ed2518 !important;
}

.swal2-icon.swal2-error [class^="swal2-x-mark-line"] {
  background-color: #ed2518 !important;
}

.swal2-icon.swal2-warning {
  border-color: #f68727 !important;
  color: #f68727 !important;
}

.swal2-title,
.swal2-content,
.swal2-html-container,
.swal2-input {
  color: #eee !important;
}

.swal2-styled.swal2-cancel {
  background-color: #ed2518 !important;
}

.swal2-styled.swal2-confirm {
  background-color: #0094ff !important;
}

.swal2-styled.swal2-confirm:disabled {
  background-color: grey !important;
  cursor: not-allowed !important;
}

.swal2-popup {
  background: #424242 !important;
}

@font-face {
  font-family: "pathway_gothic_oneregular";
  src: local("pathway_gothic_oneregular"),
    url("./fonts/pathwaygothicone-regular-webfont.woff2") format("woff2"),
    url("./fonts/pathwaygothicone-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}


.htmlCodeInnerButtonLoader {
  border: 2px solid #f3f3f3;
  border-top: 2px solid #0094ff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spinHtmlCodeLoader 2s linear infinite;
}

.resendCodeButton {
  border: 0;
  border-radius: 0.25em;
  background: initial;
  background-color: #054d80;
  color: #fff;
  font-size: 1em;
  cursor: pointer;
  margin: 0.3125em;
  padding: 0.625em 1.1em;
  transition: box-shadow 0.1s;
  box-shadow: 0 0 0 3px rgb(0 0 0 / 0%);
  font-weight: 500;
  min-width: 100px;
}

.swalHtmlInput {
  padding: 12px 20px;
  border-style: solid;
  background-color: transparent;
  color: white;
  border-radius: 5px;
  outline-color: #0094ff;
}

@keyframes spinHtmlCodeLoader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
