.landingCards {
  background: linear-gradient(90deg, /*#000835,*/ #3d12fa, #5389ff, #0d98cf);
  border-radius: 30px;
}

.homePage {
  background-image: url("./img/background.png");
  min-height: 100vh;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}
